export class WebsocketFactory {

    public static make(url: string,openHandler:any,onMessageHandler: any,onErrorHandler: any,onCloseHandler : any){
        const ws = new WebSocket(url);
        ws.onmessage = onMessageHandler;
        ws.onopen = openHandler;
        ws.onerror = onErrorHandler
        ws.onclose = onCloseHandler

        return ws;
    }


}
