<template>
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="iPad/-Главная/плашка-оплаты/неоплачено-веб" transform="translate(-653.000000, -21.000000)" fill="#CF6868">
        <path d="M680.000225,21 L655.999775,21 C654.349598,21 653.015029,22.349598 653.015029,23.9997746 L653,51 L658.999549,44.9996994 L680.000225,44.9996994 C681.649651,44.9996994 683,43.6501014 683,41.9999249 L683,23.9997746 C683,22.349598 681.649651,21 680.000225,21 L680.000225,21 Z M666.499737,25.4996618 L669.499512,25.4996618 L669.499512,34.499737 L666.499737,34.499737 L666.499737,25.4996618 Z M666.499737,37.4995116 L669.499512,37.4995116 L669.499512,40.4992861 L666.499737,40.4992861 L666.499737,37.4995116 Z" id="Fill-14"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "UnpaidSvg"
}
</script>

<style scoped>

</style>
