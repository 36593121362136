<template>
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Макеты" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Большой-десктоп-Copy" transform="translate(-267.000000, -2701.000000)">
        <g id="Group" transform="translate(267.000000, 2701.000000)">
          <circle id="Oval" fill="#000000" opacity="0.796670603" cx="13" cy="13" r="13"></circle>
          <polygon id="Rectangle" fill="#FFFFFF" transform="translate(11.000000, 13.000000) scale(-1, 1) translate(-11.000000, -13.000000) " points="5 7 17 13 5 19"></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PreviousVideoSvg"
}
</script>

<style scoped>
svg{
  cursor: pointer;
}
svg:hover{
  opacity: .8;
}
</style>