<template>
    <modal name="CamerasListModal" :width="width" height="500px" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <div class="modal">
        <CloseSvg
            class="modal__exit"
            @click.native="close"/>
        <div class="modal__list">
          <div class="modal__list__item item"
               v-for="(camera,index) in cameras"
               @click="select(camera,index)"
               :style="{'pointer-events' : (camera.status != 0) ? 'none' : 'auto'}" >
            <svg
                v-if="camera._id === selectedId"
                width="8px" height="9px" viewBox="0 0 8 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Модалки/выбрать-камеру/инструменты/выбранная-камера" transform="translate(-19.000000, -20.000000)" fill="#F54E5E" fill-rule="nonzero">
                  <path d="M26.2111456,25.3944272 L20.4472136,28.2763932 C19.9532351,28.5233825 19.3525621,28.3231581 19.1055728,27.8291796 C19.0361451,27.6903242 19,27.5372111 19,27.381966 L19,21.618034 C19,21.0657492 19.4477153,20.618034 20,20.618034 C20.1552451,20.618034 20.3083582,20.6541791 20.4472136,20.7236068 L26.2111456,23.6055728 C26.7051241,23.8525621 26.9053485,24.4532351 26.6583592,24.9472136 C26.5615955,25.140741 26.404673,25.2976635 26.2111456,25.3944272 Z" id="Line-6"></path>
                </g>
              </g>
            </svg>
            <p
                class="item__text"
                :class="{
              'item__text_selected' : camera._id === selectedId,
              'item__text_disabled' : camera.status != 0
            }">{{camera._name}}</p>
            <p class="item__interval" v-if="camera.status === 2 || camera.status === 1">{{getCameraText(camera)}}</p>
            <TimeSvg
                class="item__time"
                v-if="camera.status === 2 || camera.status === 1"/>
            <DangerSvg
                class="item__danger"
                v-if="camera.status === 3"/>
          </div>
        </div>
      </div>
    </modal>
</template>

<script>
    import CloseSvg from "@/assets/vue-icons/Modal/CloseSvg";
    import TimeSvg from "@/assets/vue-icons/Modal/TimeSvg";
    import DangerSvg from "@/assets/vue-icons/Settings/DangerSvg";
    export default {
        name: "CamerasListModal",
      components: {DangerSvg, TimeSvg, CloseSvg},
      data(){
            return {
                cameras : [],
                selectedId : 0
            }
        },
      computed:{
          width(){
            return (this.$windowWidth > 767) ? "500px" : "308px"
          }
      },
        methods:{
            onBeforeOpen(e){
              document.body.classList.add("modal-open");
              this.selectedId = e.params.selectedId
                this.cameras = [...e.params.cameras]
            },

            close(){
                this.$modal.hide("CamerasListModal")
            },


            select(camera,index){
                this.$emit("select",camera,index)
                this.close()
            },

          getCameraText(camera){
            return (camera.status === 1) ? camera.dateInterval : "пн-пт"
          },
          onBeforeClose(){
            document.body.classList.remove("modal-open");
          }
        }
    }
</script>

<style scoped lang="scss">

.modal{
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #EBEDEE;
  border-radius: 4px;

  @include media(767){
    width: 308px;
  }

  &__exit{
    margin-top: 8px;
    margin-left: 464px;

    @include media(767){
      margin-left: 274px;
    }
  }

  &__list{
    margin-top: 7px;

    width: 497px;
    height: 408px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;

    @include media(767){
      width: 305px;
    }
  }
}

.item{
    border-top: 1px solid #EBEDEE;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 13px;


    svg{
      margin-left: 6px;
    }

    &__text{
      font-family: Roboto, "sans-serif";
      font-size: 17px;
      color: #909090;
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include media(767){
        width: 200px;
      }

      &_selected{
        margin-left: 6px;
        font-family: Roboto, "sans-serif";
        line-height: 20px;
        font-size: 17px;
        color: #222222;
      }

      &_disabled{
        font-family: Roboto, "sans-serif";
        font-size: 17px;
        color: #909090;
        opacity: .5;
      }
    }


  &__interval{
    font-family: Roboto, "sans-serif";
    font-size: 10px;
    color: #C8C8C8;
    letter-spacing: 0;
    text-align: right;
    line-height: 10px;
    margin-left: 79px;
    width: 58px;


    @include media(767){
      margin-left: 0px;
    }
  }

  &__time{
    margin-left: 10px !important;
  }

  &__danger{
    margin-left: 144px !important;;
    @include media(767){
      margin-left: 52px !important;;
    }
  }



  &:last-child{
    border-bottom: 1px solid #EBEDEE;

  }
}



    *{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
</style>