












import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import * as Hls from "hls.js";
import VideoLoader from "@/components/loader/VideoLoader.vue";

@Component({
    components: {VideoLoader}
})
export default class HlsPlayer extends Vue{
    // @ts-ignore
    @Prop(String) readonly uri: string | null;
    // @ts-ignore
    @Prop(Boolean) readonly isMuted: boolean | null;


    private hlsPlayer: any = null;
    private isError = false;

    private isLoading = false;

    // @ts-ignore
    $refs:{
        video: HTMLVideoElement
    }

    @Watch("isMuted")
    onMutedChange(newVal : boolean){
        //@ts-ignore
        const video = this.$refs.hlsVideo
        video.muted = newVal;
    }


    @Watch("uri",{immediate: true,deep: true})
    onChangeUri(uri: string){
        if ((uri == null || uri == "") && this.hlsPlayer != null){
            console.log("disable blat video")
            this.hlsPlayer.destroy();
            //@ts-ignore
            let video = this.$refs.hlsVideo;
            video.pause()
        }
        else if (this.hlsPlayer != null){
            console.log(uri)
            console.log("play blat video")
            this.playVideo()
        }
    }



    playVideo () {
        this.isError = false
        if (this.hlsPlayer != null){
            console.log(this.hlsPlayer)
            this.hlsPlayer.destroy();
        }
        // @ts-ignore
        this.hlsPlayer = new Hls({
          liveBackBufferLength: 5,
          backBufferLength: 5,
          liveMaxBackBufferLength: 5,
          maxBufferSize: 0,
          maxBufferLength: 10,
          liveSyncDurationCount: 1,
        })
        // @ts-ignore
        let video = this.$refs.hlsVideo;
        video.volume = 1
        video.pause()
        // @ts-ignore
        if (Hls.isSupported()) {
            this.hlsPlayer.loadSource(this.uri)
            this.hlsPlayer.attachMedia(video)
            this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
                console.log(video)
                video.play()
            })
            this.hlsPlayer.on(Hls.Events.ERROR,  (event : any, data : any) => {
                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            // try to recover network error
                            console.log("fatal network error encountered, try to recover");
                            this.hlsPlayer.startLoad();
                            break;
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            console.log("fatal media error encountered, try to recover");
                            this.hlsPlayer.recoverMediaError();
                            break;
                        default:
                            // cannot recover
                            console.log("что то пошло не так")
                            this.hlsPlayer.destroy();
                            break;
                    }
                    this.isError = true
                }
            })

          //@ts-ignore
          this.hlsPlayer.on('hlsFragChanged', (e,data) => {
            const range = {
              startOffset: 0,
              endOffset : data.frag.startDTS
            };


            //@ts-ignore
            this.hlsPlayer.trigger('hlsBufferFlushing',range)
          })


        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = this.uri
            video.addEventListener('loadedmetadata', () => {
                console.log(video)
                video.play()
            })
        }


        video.addEventListener('waiting', (e: any) => {
            this.isLoading = true
        }, false)

        video.addEventListener('playing', (e : any) => {
            this.isLoading = false
        }, false)


        video.addEventListener('loadstart', (e: any) => {
            if (e.target.paused){
                this.isLoading = false
            } else {
                this.isLoading = true
            }
        }, false)

        video.addEventListener('timeupdate', (e: any) => {
            this.isLoading = false
        }, false)

        video.addEventListener("webkitendfullscreen", () =>{
            setTimeout(() => {                video.play()},1500)

        }, false);
    }


    mounted(): void {
        this.playVideo()
    }

}
