
















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import * as Hls from "hls.js";
import VideoLoader from "@/components/loader/VideoLoader.vue";
import FilterObject from "@/components/FilterObject.vue";

@Component({
    components: {FilterObject, VideoLoader}
})
export default class HlsPlayerNew extends Vue{
    // @ts-ignore
    @Prop(String) readonly uri: string | null;
    // @ts-ignore
    @Prop(Boolean) readonly isMuted: boolean | null;

  @Prop({default() {return {}}})
  readonly filterObject!: any;



  private mutableFilterObject : any = {}





  get computedFilterObject(){
    if (this.$refs.parentContainer == null) return {}
    console.log(this.$refs.parentContainer.offsetHeight)
    const xcoef = this.$refs.parentContainer.offsetWidth/this.filterObject.cw
    const ycoef = this.$refs.parentContainer.offsetHeight/this.filterObject.ch
    const obj ={
      top: `${this.filterObject.y*ycoef}px`,
      left: `${this.filterObject.x*xcoef}px`,
      width: `${this.filterObject.w*xcoef}px`,
      height: `${this.filterObject.h*ycoef}px`,
    }

      return obj;
  }


    public hlsPlayer: any = null;
    private isLoading = false;


    // @ts-ignore
    $refs:{
        video: HTMLVideoElement,
      parentContainer : HTMLDivElement
    }


    @Watch("isMuted")
    onMutedChange(newVal : boolean){
        //@ts-ignore
        const video = this.$refs.hlsVideo
        video.muted = newVal;

    }


    playVideo () {
        if (this.hlsPlayer != null){
            console.log(this.hlsPlayer)
            this.hlsPlayer.detachMedia();
        } else {
            // @ts-ignore
            this.hlsPlayer = new Hls({              liveBackBufferLength: 5,
              backBufferLength: 5,
              liveMaxBackBufferLength: 5,
              maxBufferSize: 0,
              maxBufferLength: 10,
              liveSyncDurationCount: 1,})
        }
        // @ts-ignore
        let video = this.$refs.hlsVideo;
        video.volume = 1.0
        video.pause()
        // @ts-ignore
        if (Hls.isSupported()) {
            this.hlsPlayer.loadSource(this.uri)
            this.hlsPlayer.attachMedia(video)
            this.hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
                console.log(video)
                video.play()
            })
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = this.uri
            video.addEventListener('loadedmetadata', () => {
                console.log(video)
                video.play()
            })
        }

        video.addEventListener('waiting', (e: any) => {
            this.isLoading = true
        }, false)

        video.addEventListener('playing', (e : any) => {
            this.isLoading = false
        }, false)

        video.addEventListener('loadstart', (e: any) => {
            if (e.target.paused){
                this.isLoading = false
            } else {
                this.isLoading = true
            }
        }, false)

        video.addEventListener('error', (e: any) => {
            this.isLoading = false
        }, false)

        video.addEventListener('timeupdate', (e: any) => {
            this.isLoading = false
        }, false)


        video.addEventListener("webkitendfullscreen", () =>{
            setTimeout(() => {                video.play()},1500)

        }, false);

      //@ts-ignore
      this.hlsPlayer.on('hlsFragChanged', (e,data) => {
        const range = {
          startOffset: 0,
          endOffset : data.frag.startDTS
        };


        //@ts-ignore
        this.hlsPlayer.trigger('hlsBufferFlushing',range)
      })


      this.mutableFilterObject = this.computedFilterObject
    }


    stopVideo(){
        if (this.hlsPlayer != null){
            console.log(this.hlsPlayer)
            this.hlsPlayer.detachMedia();
        }
    }


    fullStop(){
        if (this.hlsPlayer != null){
            this.hlsPlayer.destroy()
        }
    }
}
