<template>

  <div class="unpaid" :class="[`unpaid_${type}`]">
    <div class="unpaid__info" :class="[`unpaid__info_${type}`]">
      <p class="unpaid__title"
         :class="{
                  'unpaid__title_weekend' : type === 'weekend',
                  'unpaid__title_holiday' : type === 'holiday',
                  'unpaid__title_unpaid' : type === 'unpaid'
            }">{{title}}</p>
      <p class="unpaid__text" :class="[`unpaid__text_${type}`]">{{text}}</p>
    </div>
    <p
        v-if="type === 'weekend'"
        class="unpaid__class-text">{{className}}  {{(typeSchool === 1) ? "группа" : "класс"}}</p>
    <UnpaidSvg
        v-if="type === 'unpaid'"
      class="unpaid__danger"/>
  </div>
</template>

<script>
import UnpaidSvg from "@/assets/vue-icons/Settings/UnpaidSvg";
export default {
  name: "UnpayedCard",
  components: {UnpaidSvg},
  props:{
    title : String,
    text : String,
    className : String,
    type : String,
    letter: String,
    typeSchool: Number
  },
}
</script>

<style scoped lang="scss">
  .unpaid{
    background: #FFFFFF;
    border: 1px solid #EBEDEE;
    border-radius: 4px;

    width: 800px;
    height: 70px;
    display: flex;
    justify-content: space-between;

    @include media(1200){
      width: 700px;
    }

    @include media(767){
      width: 375px;
    }

    &_unpaid{
      height: 110px;
    }


    &__info{
      margin-top: 14px;
      margin-left: 16px;
      width: 230px;


      &_unpaid{
        margin-top: 21px;
        margin-left: 16px;
        width: 455px;

        @include media(1200){
          width: 280px;
        }
      }
    }

    &__title{
      font-family: CodeNext;
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 21px;

      &_weekend{
        color: #8AD736;
      }

      &_holiday{
        color: #F5A623;
      }

      &_unpaid{
        color: #CF6868;
        line-height: 20px;
      }
    }

    &__text{
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      line-height: 18px;

      &_unpaid{
        font-family: Golca;
        font-weight: 100;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        line-height: 17px;
      }
    }

    &__class-text{
      margin-top: 17px;
      margin-right: 17px;
      width: 98px;
      height: 26px;
      background: #F6F7F8;
      border-radius: 13px;
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      vertical-align: middle;
      font-size: 13px;
      color: #788396;
      letter-spacing: 0;
      text-align: center;
      line-height: 26px;

      pointer-events: none;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    &__danger{
      margin-right: 17px;
      margin-top: 21px;
    }
  }
</style>
